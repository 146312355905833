[data-v-0314420c] .common_tree_handle {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.formContentBox[data-v-0314420c] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-0314420c] {
  width: 100%;
  height: 100%;
}
.formTopic[data-v-0314420c] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.shuttleBackBox[data-v-0314420c] {
  width: 100%;
  height: calc(100% - 160px);
}
.shuttleBackBox .main-box[data-v-0314420c] {
  height: calc(100% - 56px);
  overflow: scroll;
}
.shuttleBackBox .el-tabs[data-v-0314420c] {
  width: 100%;
}
.shuttleBackBox .upload-demo[data-v-0314420c] {
  background-color: #ffffff;
  padding-left: 10px;
}
.shuttleBackBox .table-box[data-v-0314420c] {
  height: 100%;
  width: calc(100% - 280px);
}
.shutleBack[data-v-0314420c] {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.leftRightBtn[data-v-0314420c] {
  margin: 211px 12px;
}
.shutleTitle[data-v-0314420c] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent[data-v-0314420c] {
  padding: 6px;
  height: 400px;
  overflow: auto;
}
.deptBox[data-v-0314420c] {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.unitBox[data-v-0314420c] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}
.unitTitle[data-v-0314420c] {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}
.content[data-v-0314420c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content .img-content[data-v-0314420c] {
  width: 188px;
  height: 100px;
  padding: 3px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 12px;
}
.content .img-content .delete-icon[data-v-0314420c] {
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 16px;
  color: red;
}
.content .img-content img[data-v-0314420c] {
  width: 100%;
  height: 100%;
}