.content[data-v-219ff93a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.content .img-content[data-v-219ff93a] {
  width: 188px;
  height: 100px;
  padding: 3px;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 12px;
}
.content .img-content .delete-icon[data-v-219ff93a] {
  position: absolute;
  top: -6px;
  right: -6px;
  font-size: 16px;
  color: red;
}
.content .img-content img[data-v-219ff93a] {
  width: 100%;
  height: 100%;
}